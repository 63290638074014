import React, {Suspense} from 'react';
import Layout from "./hoc/Layout/Layout";
import {createTheme, StyledEngineProvider, Theme, ThemeProvider} from '@mui/material/styles';
import WizardContext, {defaultWizardContext} from "./store/wizard-context";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import StepChecker from "./components/StepChecker/StepChecker";
import Step1 from "./pages/Step1/Step1";
import Step3 from "./pages/Step3/Step3";
import Step4 from "./pages/Step4/Step4";
import AlgemeneVoorwaarden from "./pages/AlgemeneVoorwaarden/AlgemeneVoorwaarden";
import PrivacyStatement from "./pages/PrivacyStatement/PrivacyStatement";
import GratisInfoPagina from "./pages/GratisInfoPagina/GratisInfoPagina";
import OrderStatus from "./pages/OrderStatus/OrderStatus";
import {is_gratis} from "./store/gratis-of-bestel";
import Start from './pages/Start/Start';
import {Box, CircularProgress} from "@mui/material";
import BestelInfoPagina from "./pages/BestelInfoPagina/BestelInfoPagina";

const Step2 = React.lazy(() => import("./pages/Step2/Step2"));

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}

const theme = createTheme({
  palette: {
    text: {
      primary: "#666",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      }
    }
  },
  breakpoints: {
    values: {
      xs: 380,
      sm: 650,
      md: 768,
      lg: 1024,
      xl: 1366,
    },
  }
});

const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <WizardContext.Provider value={defaultWizardContext}>
        <BrowserRouter>
          <Layout>
            <Suspense fallback={<Box sx={{paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3), textAlign: "center"}}>
              <CircularProgress size={128}/>
            </Box>}>
              <Routes>
                <Route path="/" element={<Navigate to="/step1"/>}/>
                <Route path="/step1" element={<StepChecker step={1}><Step1/></StepChecker>}/>
                <Route path="/step2" element={<StepChecker step={2}><Step2/></StepChecker>}/>
                {is_gratis && <Route path="/step3" element={<StepChecker step={3}><Step3/></StepChecker>}/>}
                <Route path="/step4" element={<StepChecker step={4}><Step4/></StepChecker>}/>
                <Route path="/order/success" element={<OrderStatus success={true} />}/>
                <Route path="/order/:orderLongId/process" element={<OrderStatus />}/>
                <Route path="/algemenevoorwaarden" element={<AlgemeneVoorwaarden/>}/>
                <Route path="/privacy" element={<PrivacyStatement/>}/>
                <Route path="/informatie" element={is_gratis ? <GratisInfoPagina/> : <BestelInfoPagina />}/>
                <Route path="/start/:startParams/:locationId" element={<Start />} />
                <Route path="*" element={<p>Deze pagina bestaat niet.</p>} />
              </Routes>
            </Suspense>
          </Layout>
        </BrowserRouter>
      </WizardContext.Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
